import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BusinessGroup, GiftCardSettingsBoxUpdateRequest, GiftCardSettingsDrawRegion, GiftCardSettingsResponse, GiftCardSettingsUpdateRequest, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

function getCoordinates(event: MouseEvent | TouchEvent): [number, number] {
  let div = <HTMLElement>event.target;
  while (!(div instanceof HTMLImageElement) && div.parentNode) {
    div = div.parentNode as HTMLElement;
  }
  if (!div) {
    return [0, 0];
  }
  let rect = div.getBoundingClientRect();
  let clientX, clientY;
  if ("touches" in event) {
    if (event.touches.length) {
      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;
    } else {
      clientX = event.changedTouches[event.changedTouches.length - 1].clientX;
      clientY = event.changedTouches[event.changedTouches.length - 1].clientY;
    }
  } else {
    clientX = event.clientX;
    clientY = event.clientY;
  }
  let x = (clientX - rect.left) / rect.width;
  let y = (clientY - rect.top) / rect.height;
  return [toFixedNumber(x, 5), toFixedNumber(y, 5)];
}

function toFixedNumber(value: number, digits: number) {
  let pow = Math.pow(10, digits);
  return Math.round(value * pow) / pow;
}

function toCss(gcfb?: GiftCardSettingsBoxUpdateRequest) {
  if (!gcfb?.coords.length) {
    return "";
  }
  return `left: ${gcfb.coords[0] * 100}%; top: ${gcfb.coords[1] * 100}%; width: ${gcfb.coords[2] * 100}%; height: ${gcfb.coords[3] * 100}%;`;
}

let box: GiftCardSettingsBoxUpdateRequest = {
  drawRegion: 'QR_NUMBER',
  coords: [0, 0, 0, 0],
  horizontalAlign: "CENTER",
  verticalAlign: "CENTER",
  fillColor: "#000000",
  strokeColor: "#000000",
  strokeWidth: 0,
  font: "HELVETICA",
  fontSize: 15,
  lineSpacing: 1.5,
  messageFormat: "{0}",
};

@autoinject
export class GiftCardsSettingsEdit {
  private businessGroupList: BusinessGroup[] = [];

  private settings: GiftCardSettingsUpdateRequest = {
    name: "",
    businessGroupId: 0,
    boxes: [
      { ...box },
      { ...box, drawRegion: "NUMBER" },
      { ...box, drawRegion: "VALUE" },
      { ...box, drawRegion: "NOT_AFTER_DATE", messageFormat: "{0,date,dd.MM.yyyy}" },
    ],
    pdf: "",
  };
  private modifyTime?: Date;
  private pages = 1;

  @observable
  private pdfFile?: FileList;

  private drawRegion?: GiftCardSettingsDrawRegion;
  private box?: GiftCardSettingsBoxUpdateRequest;
  private drawRegionMouseDown = false;

  @computedFrom("settings.modifyTime")
  get pdfImageFile() {
    if (this.settings.modifyTime && this.settings.id) {
      return this.api.giftCardSettingsRenderUrl({ id: this.settings.id, modifyTime: this.settings.modifyTime });
    }
    return undefined;
  }

  @computedFrom("settings.id")
  get sampleUrl() {
    if (!this.settings.id) {
      return "";
    }
    return this.api.giftCardSettingsSampleUrl({ id: this.settings.id });
  }

  @computedFrom("box.coords")
  get drawRegionStyle() {
    return toCss(this.box);
  }

  @computedFrom("drawRegion", "settings.boxes")
  get regions() {
    let keyList: GiftCardSettingsDrawRegion[] = ["QR_NUMBER", "NUMBER", "VALUE", "NOT_AFTER_DATE", "FREE_TEXT", "RECIPIENT_NAME"];
    return keyList.map(key => {
      let keyBox: GiftCardSettingsBoxUpdateRequest | undefined = this.settings.boxes.find(x => x.drawRegion === key);
      if (!keyBox) {
        return undefined;
      }
      return {
        name: key,
        style: toCss(keyBox),
      };
    }).filter(x => !this.drawRegion || (this.drawRegion && this.drawRegion != x?.name));
  }

  constructor(private router: Router, private api: MyHttpApi, private i18n: I18N, private notify: Notify) {
  }
  determineActivationStrategy() {
    return "replace";
  }

  async activate(params: { id: string; }) {
    this.businessGroupList = await this.api.businessGroupList();
    if (params.id) {
      const settings = await this.api.giftCardSettingsById({ id: parseInt(params.id) });
      this.settings = this.buildUpdateRequestFromResponse(settings);
    }
  }

  buildUpdateRequestFromResponse(settings: GiftCardSettingsResponse): GiftCardSettingsUpdateRequest {
    this.pages = settings.pages;
    return {
      ...settings.settings,
      boxes: [...settings.boxes],
    };
  }

  async delete() {
    if ("id" in this.settings) {
      if (!this.settings.id) {
        return;
      }
      if (!confirm(this.i18n.tr("common.confirmDelete"))) {
        return;
      }
      await this.api.giftCardSettingsDelete({ id: this.settings.id, });
      this.router.navigateBack();
    }
  }

  async setRegion(region: GiftCardSettingsDrawRegion) {
    this.drawRegion = region;
    this.box = this.settings.boxes.find(b => b.drawRegion === this.drawRegion) || {
      ...box,
      drawRegion: region,
      messageFormat: region === "NOT_AFTER_DATE" ? "{0,date,dd.MM.yyyy}" : "{0}",
    };
    this.settings.boxes = this.settings.boxes.filter(x => x.drawRegion !== this.drawRegion);
    this.settings.boxes.push(this.box);

  }

  async saveRegion() {
    if (!this.drawRegion || !this.box) {
      return;
    }
    this.settings.boxes = this.settings.boxes.filter(x => x.drawRegion !== this.drawRegion);
    this.settings.boxes.push({ ...this.box });
    this.drawRegion = undefined;
    const settings = await this.api.giftCardSettingsUpdate(this.settings);
    this.settings = this.buildUpdateRequestFromResponse(settings);
  }

  async clearRegion() {
    if (!this.drawRegion) {
      return;
    }
    this.settings.boxes = this.settings.boxes.filter(x => x.drawRegion !== this.drawRegion);
    await this.saveObject();
    this.drawRegion = undefined;
  }

  cancelRegion() {
    this.drawRegion = undefined;
  }

  imageMouseDown(event: MouseEvent) {
    if (!this.box) {
      return;
    }
    let coords = getCoordinates(event);
    this.drawRegionMouseDown = true;
    this.box.coords = [coords[0], coords[1], 0, 0];
  }

  imageMouseMove(event: MouseEvent) {
    if (this.drawRegionMouseDown && this.box) {
      let drc = this.box.coords;
      let coords = getCoordinates(event);
      this.box.coords = [drc[0], drc[1], toFixedNumber(coords[0] - drc[0], 5), toFixedNumber(coords[1] - drc[1], 5)];
    }
  }

  imageMouseUp(event: MouseEvent) {
    this.imageMouseMove(event);
    this.drawRegionMouseDown = false;
  }

  async save() {
    await this.saveObject();
    this.router.navigateToRoute("gift-cards/settings/list");
  }

  async copy() {
    if (!this.settings.id) {
      return;
    }
    await this.saveObject();
    this.settings.name += this.i18n.tr("giftCard.copy");
    this.settings.id = undefined;
  }

  async saveObject() {
    let keyList: GiftCardSettingsDrawRegion[] = ["QR_NUMBER", "NUMBER", "VALUE", "NOT_AFTER_DATE"];
    for (let key of keyList) {
      let value = this.settings.boxes.find(x => x.drawRegion === key);
      if (!value || value.coords.length != 4) {
        await this.setRegion(key);
      }
    }
    const settings = await this.api.giftCardSettingsUpdate(this.settings);
    this.settings = this.buildUpdateRequestFromResponse(settings);
  }

  pdfFileChanged() {
    if (!this.pdfFile) {
      return;
    }
    if (!this.settings.businessGroupId) {
      this.notify.info("giftCard.businessGroupIsMandatory", {});
      this.pdfFile = undefined;
      return;
    }
    let fr = new FileReader();
    fr.onload = async () => {
      let result = <string>fr.result;
      this.settings.pdf = result.replace(/^[^,]+,/, "");
      const settings = await this.api.giftCardSettingsUpdate(this.settings);
      // * Uploading a file save the settings, so fix url
      if (!this.settings.id) {
        this.router.navigateToRoute("gift-cards/settings/edit", { id: "" + settings.settings.id });
      }
      this.settings = this.buildUpdateRequestFromResponse(settings);
    };
    fr.readAsDataURL(this.pdfFile[0]);
  }
}
